import React from 'react';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout page="404" metaTitle="UTUC: 404 - Page not found">
    <div style={{ minHeight: '178px', backgroundColor: '#032d46'}} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
          width: '100%',
          flexDirection: 'column',
          background: 'white',
        }}
      >
        <h1>NOT FOUND</h1>
        <p>
          Please <Link to="/">return to the homepage</Link>.
        </p>
      </div>
  </Layout>
);

export default NotFoundPage;
